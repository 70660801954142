@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App {
  text-align: center;
  background: linear-gradient(45deg, #0b0b3b, #3430ac);
  background-size: 200% 200%;
  animation: gradientAnimation 15s ease infinite;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  position: relative;
}

.App-header {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
}

.y2k-title {
  font-family: "Orbitron", sans-serif;
  color: #00ffea;
  text-shadow: 0 0 20px #00ffea;
}

.y2k-text {
  font-family: "Chakra Petch", sans-serif;
  color: #f6ff00;
  text-shadow: 0 0 10px #f6ff00;
}

.y2k-button {
  background-color: #ff009e;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.y2k-button:hover {
  background-color: #cf00b2;
  transform: scale(1.1);
}

.random-facts {
  margin-top: 30px;
  font-family: "Chakra Petch", sans-serif;
  color: #4affee;
}

/* New styles for gallery */
.gallery {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Footer styling */
.App-footer {
  margin-top: auto;
  padding: 20px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
}
.background-canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.image-placeholder {
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  border: 2px dashed #fff;
  /* Random positioning */
  top: calc(10% + (80% * random()));
  left: calc(10% + (80% * random()));
}

@supports (-webkit-touch-callout: none) {
  /* For Safari: add webkit prefix for the keyframes and animation */
  @-webkit-keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .App {
    -webkit-animation: gradientAnimation 15s ease infinite;
  }
}
